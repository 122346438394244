import { createAction, props } from "@ngrx/store";
import { Asset, AssetData, EmailLogin, ISocketWallpaperRes } from "src/app/models/asset.modal";
import { acccountData } from "src/app/models/accountData.model";

export const login = createAction(
  "[Kiosk Login API] kiosk login",
  props<{ pin?: string; email?: EmailLogin }>()
);

export const getAssetData = createAction(
  "[Kiosk Login API] Load Kiosk Asset",
  props<{ lpin: string }>()
);

export const getAssetDataSuccess = createAction(
  "[Kiosk Login API] Load Kiosk Asset Success",
  props<{ res: Asset }>()
);

export const getAssetDataFailure = createAction(
  "[Kiosk Login API] Load Kiosk Asset Failure",
  props<{ error: Error }>()
);

export const setAuthCredentials = createAction(
  "[Kiosk Login API] saving credentials",
  props<{ res: Asset; pinLogin: boolean }>()
);

export const navigateToWallpaper = createAction(
  "[Kiosk Login API] navigate to wallpaper"
);

export const navigateToStores = createAction(
  "[Kiosk Login API] navigate to stores"
);

export const navigateToHome = createAction("[Kiosk Login API] routing to home");

export const getAccountData = createAction(
  "[Kiosk Login API] get AccountData",
  props<{ token: string; lPin: string }>()
);

export const getAccountDataSuccess = createAction(
  "[Kiosk Login API] getAccountData Success",
  props<{ res: acccountData; lPin: string }>()
);

export const getAccountDataFailure = createAction(
  "[Kiosk Login API] get AccountData Failure",
  props<{ error: Error }>()
);

export const cachedLogin = createAction(
  "[Kiosk Login API] Cached Login in Kiosk",
  props<{ loginData: AssetData }>()
);

export const wrongCredential = createAction(
  "[Kiosk Login API] Login Error",
  props<{ error: boolean }>()
);

export const socketConnectionForSetting = createAction(
  "[Socket connection] socket connection for setting",
  props<{ accountId: string }>()
);

export const socketConnectionForProducts = createAction(
  "[Socket connection] socket connection for products",
  props<{ accountId: string; storeId }>()
);

export const socketConnectionForInventoryProductUpdate = createAction(
  "[Socket connection] socket connection for inventroy product products",
  props<{ accountId: string; storeId: string }>()
);

export const socketConnectionForPayment = createAction(
  "[Socket connection] socket connection for payment update"
);

export const socketConnectionForWallpapers = createAction(
  "[Socket connection] socket connection for wallpapers",
  props<{ terminalId: string }>()
);

export const updateWallpaperFromSocket = createAction(
  "[Kiosk Store] Update Wallpaper by Socket",
  props<{data: ISocketWallpaperRes }>()
);

export const disconnectPaymentSocketConnection = createAction(
  "[Socket connection] disconnecting payment socket after payment success",
  props<{ orderId: string }>()
);

export const fillStateOnPageRefresh = createAction(
  "[For page refresh] loading products, setting and connecting socket"
);

export const socketConnectionForPrintReceipt = createAction(
  "[Socket Conection] socket connection to print receipt from iotzen"
);
