import {createAction, props} from '@ngrx/store';
import {Store} from 'src/app/models/store.model';
import { IContactDetails, IPaymentDetails } from './stores.reducer';

export const getAvailableStores = createAction(
  '[Stores API] Load Stores list',
  props<{ sk_lpin: string }>()
);

export const getAvailableStoresSuccess = createAction(
  '[Stores API] Load Stores list success',
  props<{ res: Store[] }>()
);

export const getAvailableStoresFailure = createAction(
  '[Stores API] Load Stores list failure',
  props<{ error: Error }>()
);


export const getStorePaymentDetails = createAction(
  '[Stores API] Get Store Payment Details',
  props<{ storeId: string }>()
);

export const getStoreContactDetails = createAction(
  '[Stores API] Get Store Contact Details',
  props<{ storeId: string }>()
);

export const getStorePaymentDetailsSuccess = createAction(
  '[Stores API] Get Store Payment Details Success',
  props<{ paymentDetails: IPaymentDetails,storeId: string }>()
);

export const getStoreContactDetailsSuccess = createAction(
  '[Stores API] Get Store Contact Details Success',
  props<{ contactDetails: IContactDetails,storeId: string }>()
);