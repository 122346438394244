export const CONSTANTS = {
  KIOSK_TYPE: {
    SUPER_KIOSK_TERMINAL: 'superkTerminal',
    KIOSK_TERMINAL: 'kTerminal'
  },
  OUT_OF_STOCK_STATUS: 'outOfStockStatus',
  QUANTITY: 'quantity',
  IS_INVENTORY_ENABLED: 'isInventoryEnabled'
};

export const WW = {
  E_CONNECTIVITY: 'connectivity',
  E_PRINTER_STATUS: 'printerStatus',
  E_PRINT: 'print',
  E_RXDB_INIT: 'initRxdb'
};




export const DINETYPE = {
  DINE_IN: 'dineIn',
  DINE_OUT: 'dineOut',
  TABLE: 'table',
  OTHER_SERVICES: 'otherServices',
};

export const PRINT = {
  T_ORDER_REC: 'tableOrderReciept',
  T_ON_TABLE_PAY: 'tableOrderInvoice',
  T_ON_TABLE_SO_UPDATE: 'tableOrderCancelledKitchenToken',
  T_ON_TABLE_SEND_KITCHEN: 'tableOrderKitchenToken',
  T_PRODUCT_SALES_REPORT: 'productSaleReport',
  KIOSK_ORDER_INVOICE: 'kioskOrderInvoice',
  KIOSK_ORDER_KITCHEN_TOKEN: 'kioskOrderKitchenToken',
  KIOSK_ORDER_BILL: 'kioskOrderBill',
  TYPE: {
    K_AGENT: 'kAgent',
    EPSON: 'epson',
    USB: 'local',
    I_MIN: 'imin',
  }
};

export interface IStatus {
  isConnected: boolean;
  event: string;
  type?: string;
  name?: string;
}

export interface IPrinterStatus {
  status: Record<string, IStatus>;
  allConnected?: boolean;
}

export interface IReceiptTemplate {
  templates: {
    _id: string
    type: string
    content: string
    subContent: {
      fieldsPresent: string[]
      __TAX__DETAILS__?: string
      __ITEM__DETAILS__?: string
      __PARCEL__CHARGE__?: string
      __TAX__SUMMARY__?: string
      __GRAND__TOTAL__CONTENT__?: string
      __PRODUCT__DETAILS__?: string
      __CATEGORY__INFO__?: string
      __CATEGORY__SUMMARY__?: string
      __PRODUCT__DETAILS__INNER__?: string
    }
  }[];
  assetsInfo: {
    _id: string
    assetType: string
    name: string
    ipaddress: string
    serialNumber: string
    recieptTypes: string[]
    printerType: string
    isKot: boolean
    accountId: string
    updated: string
    created: string
    __v: number
    assetsLinked: string[]
    aProperties: {
      status: string
    }
    groups: any
    serviceAssets: any
  }[];
}


export const PAYMENTMODE = {
  CASH: 'Cash',
  ONLINE: 'Online'
};
